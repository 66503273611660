import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { reservationsRef } from '../../database'
import moment from 'moment'
import { onSnapshot, query, where, addDoc } from 'firebase/firestore'
import emailjs from '@emailjs/browser'

import {
  updateReservations,
  removeReservations,
  bookReservationsSuccess,
  bookReservationsFailure,
  addNotification
} from '../actions'
import { uiConstants } from '../../constants'

export let reservationsSnap

export function* loadReservationsSaga() {
  try {
    const startDate = moment.utc().add('-7', 'days').unix()
    const listener = eventChannel((emit) => {
      const q = query(reservationsRef, where('date', '>=', startDate))
      reservationsSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => reservationsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateReservations({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateReservations({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeReservations({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* bookReservationsSaga(action) {
  try {
    yield addDoc(reservationsRef, action.payload)

    yield emailjs.send(
      uiConstants.emailjs.serviceId,
      uiConstants.emailjs.templateId,
      { ...action.payload, message: JSON.stringify(action.payload) },
      uiConstants.emailjs.publicKey
    )

    yield put(bookReservationsSuccess())
    yield put(addNotification(uiConstants.messages.bookSuccess))
  } catch (error) {
    yield put(bookReservationsFailure())
    yield put(
      addNotification(
        uiConstants.messages.bookFailure,
        uiConstants.notification.error
      )
    )
  }
}

import { notificationConstants } from '../constants'
import { timeHelper, securityHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function addNotification(
  message,
  severity = uiConstants.notification.info
) {
  return (dispatch) => {
    const notification = {
      message,
      time: timeHelper.now(),
      guid: securityHelper.guid(),
      severity
    }
    dispatch(pushNotificationToQueue(notification))
    setTimeout(() => {
      dispatch(removeNotification(notification.guid))
    }, 7000)
  }
}

function pushNotificationToQueue(payload) {
  return {
    type: notificationConstants.PUSH_NOTIFICATION,
    payload
  }
}

export function removeNotification(guid) {
  return {
    type: notificationConstants.REMOVE_NOTIFICATION,
    payload: guid
  }
}

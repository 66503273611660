import { timeHelper } from './time.helpers'

export const calendarHelper = {
  getList
}

function getList(events, reservations, time, day) {
  const e = (events || [])
    .filter((e) => {
      return timeHelper.isSameDay(e.date, time, day)
    })
    .map((x) => {
      return { ...x, type: 'event' }
    })
  const r = (reservations || [])
    .filter((e) => {
      return timeHelper.isSameDay(e.date, time, day)
    })
    .map((x) => {
      return { ...x, type: 'reservation' }
    })
  return [...e, ...r]
}

import moment from 'moment'

const timeFrom = (eventDate) => {
  const sec_num = moment(eventDate).diff(moment(), 'seconds')

  const days = Math.floor(sec_num / 3600 / 24)
  const hh = Math.floor(sec_num / 3600) % 24
  const mm = Math.floor(sec_num / 60) % 60
  const ss = sec_num % 60
  let res = ''
  if (days.toFixed().length === 1) res += '0'
  res += `${days}:`

  if (hh.toFixed().length === 1) res += '0'
  res += `${hh}:`

  if (mm.toFixed().length === 1) res += '0'
  res += `${mm}:`

  if (ss.toFixed().length === 1) res += '0'
  res += `${ss}`

  return res
}

const secondsTo = (date) => {
  return moment(date).diff(moment(), 'seconds')
}

const daysAgo = (days) => {
  return moment.utc().add(days, 'days').unix()
}

function timeToHours(input) {
  const sd = moment.unix(input).utc().startOf('day').utc().unix()
  const diff = input - sd
  let m = diff % 3600
  let h = (diff - m) / 3600
  if (m.toString().length === 1) {
    m = `0${m}`
  }
  if (h.toString().length === 1) {
    h = `0${h}`
  }
  return `${h}:${m}`
}

function now() {
  return moment.utc().unix()
}

function timeToDate(input) {
  return moment.unix(input).utc().format('DD MMMM YYYY')
}

function timeToDateFull(input) {
  return moment.unix(input).utc().format('YYYY-MM-DD HH:mm')
}

function dayOfWeek(input, format = 'dddd') {
  return moment.unix(input).utc().format(format)
}

function month(input) {
  return moment.unix(input).utc().format('MMMM')
}

function monthYear(input) {
  return moment.unix(input).utc().format('MMMM YYYY')
}

function startOfMonth(input) {
  if (input) {
    return moment.unix(input).utc().startOf('month').unix()
  }
  return moment.utc().startOf('month').unix()
}

function daysInMonth(input) {
  return moment.unix(input).utc().daysInMonth()
}

function addMonths(input, value) {
  return moment.unix(input).utc().add(value, 'months').unix()
}

function isSameDay(a, time, b) {
  return moment
    .unix(a)
    .utc()
    .isSame(moment.unix(time).utc().add(b, 'days'), 'day')
}

function addDays(input, value) {
  return moment.unix(input).utc().add(value, 'days').unix()
}

function startOfDay(input) {
  return moment.unix(input).utc().startOf('day').unix()
}

function startOfPreviousMonth() {
  return moment().utc().subtract(1, 'months').startOf('month').unix()
}

export const timeHelper = {
  timeFrom,
  secondsTo,
  daysAgo,
  now,
  timeToHours,
  timeToDate,
  timeToDateFull,
  dayOfWeek,
  month,
  startOfMonth,
  startOfPreviousMonth,
  monthYear,
  daysInMonth,
  addMonths,
  addDays,
  isSameDay,
  startOfDay
}
